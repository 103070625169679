import { routingLazyLoad } from '@/helpers/functions';

const myLoansRoutes = [
  {
    path: '/my-loans/',
    component: routingLazyLoad('Cabinet'),
    meta: {
      title: 'Мои займы',
      sidebar: true,
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        component: routingLazyLoad('LoansList', 'Loan'),
        meta: {
          sidebar: true,
          title: 'Мои займы',
        },
        children: [{
          path: '',
          name: 'LoansList',
          component: routingLazyLoad('LoanListActive', 'Loan'),
          meta: {
            title: 'Мои займы',
            sidebar: true,
          },
        },
        ],
      },
      {
        path: 'active-loan/',
        name: 'LoanItem',
        component: routingLazyLoad('LoanItem', 'Loan'),
        meta: {
          sidebar: true,
          title: false,
        },
      },
      {
        path: 'pay-online/',
        component: routingLazyLoad('PayOnline'),
        meta: {
          sidebar: true,
        },
        children: [
          {
            path: '',
            name: 'PayOptions',
            component: routingLazyLoad('PayOptions', 'PayOnline'),
            props: true,
            meta: {
              sidebar: true,
              title: 'Оплатить онлайн',
            },
          },
          {
            path: 'card/',
            name: 'CardPayIn',
            component: routingLazyLoad('CardPayIn'),
            meta: {
              sidebar: true,
              title: 'Оплатить онлайн',
            },
          },
          // {
          //   path: 'payment-sbp/',
          //   name: 'SBPPayIn',
          //   component: routingLazyLoad('SBPPayIn'),
          //   meta: {
          //     sidebar: true,
          //     title: 'Оплатить с СБП',
          //   },
          // },
          {
            path: 'confirmation/',
            name: 'Confirmation',
            component: routingLazyLoad('CardPayInCVC', 'CardPayIn'),
            meta: {
              canNotGoFromUrl: true,
              sidebar: true,
              title: 'Оплатить онлайн',
            },
          },
        ],
      },
      {
        path: 'get-requisites/',
        name: 'GetRequisites',
        component: routingLazyLoad('GetRequisites'),
        props: true,
        meta: {
          canNotGoFromUrl: true,
          sidebar: true,
          title: 'Получить реквизиты для оплаты',
        },
      },
      {
        path: 'signing/',
        name: 'Signing',
        component: routingLazyLoad('SigningContract'),
        meta: {
          title: 'Мои займы',
          sidebar: true,
        },
      },
      {
        path: 'car-photos/',
        name: 'edit:car_photos',
        component: routingLazyLoad('ProfileCarPhotos', 'Profile'),
        meta: {
          sidebar: true,
          breadcrumb: 'Фотографии автомобиля',
          title: 'Фотографии автомобиля',
          canNotGoFromUrl: true,
        },
      },
      {
        path: 'bank-card/',
        name: 'edit:bank_card',
        component: routingLazyLoad('ProfileBankCard', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Банковская карта',
          type: 'edit',
        },
      },
      {
        path: 'personal/',
        name: 'edit:personal_info',
        component: routingLazyLoad('ProfilePersonal', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Контактная информация',
          type: 'edit',
        },
      },
      {
        path: 'documents/',
        name: 'edit:passport_photos',
        component: routingLazyLoad('ProfileDocuments', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Документы',
          type: 'edit',
        },
      },
      {
        path: 'address/',
        name: 'edit:personal_address',
        component: routingLazyLoad('ProfileAddress', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Адрес',
          breadcrumb: 'Адрес',
          type: 'edit',
        },
      },
      {
        path: 'job/',
        name: 'edit:job_income',
        component: routingLazyLoad('ProfileJob', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Занятость и доходы',
          breadcrumb: 'Занятость и доходы',
          type: 'edit',
        },
      },
      {
        path: 'car-info/',
        name: 'edit:car_info',
        component: routingLazyLoad('ProfileCarInfo', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Информация о машине',
          breadcrumb: 'Информация о машине',
          type: 'edit',
          canNotGoFromUrl: true,
        },
      },
      {
        path: 'car-doc-photos/',
        name: 'edit:car_doc_photos',
        component: routingLazyLoad('ProfileCarDocs', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Документы на машину',
          breadcrumb: 'Документы на машину',
          type: 'edit',
          canNotGoFromUrl: true,
        },
      },
      {
        path: 'full-info/',
        name: 'edit:full_info_profile',
        component: routingLazyLoad('ProfileFullInfo', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Проверка данных',
          type: 'edit',
          breadcrumb: 'Проверка данных',
        },
      },
    ],
  },
];

export default myLoansRoutes;
